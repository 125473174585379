import React from 'react'
import './editor.css'
import {
    Autocomplete,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    InputAdornment, MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {EditorState} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useSnackbar} from "notistack";
import 'dayjs/locale/fr';
import ApiHelper, {ApiHelperMedia} from "../../Helper/ApiHelper";
import {useNavigate, useParams} from 'react-router-dom';
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import {Image} from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';

import ExampleTheme from "./RichTextExample";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./RichTextPlugin/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import {$generateHtmlFromNodes} from '@lexical/html';

import CodeHighlightPlugin from "./RichTextPlugin/CodeHighlightPlugin";
import AutoLinkPlugin from "./RichTextPlugin/AutoLinkPlugin";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";

function Placeholder() {
    return <div className="editor-placeholder">Entrez votre texte...</div>;
}

export default function ArticleForm () {
    const [articleThemes, setArticleThemes] = React.useState([]);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [lunched, setLunched] = React.useState(false);
    const [textEditor, setTextEditor] = React.useState('');
    let emptyJsonEditor = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'
    const [formData, setFormData] = React.useState( {
            name: '',
            text: '',
            readingTime: '',
            image: '',
            articleTheme: {id: ''},
            nextArticleId: "",
            textEditor: emptyJsonEditor
        }
    );
    const [newTheme, setNewTheme] = React.useState('');
    const [error, setError] = React.useState({
        name: false,
        text: false,
        readingTime: false,
        image: false,
        articleTheme: false,
        nextArticleId: false
    })
    const [articles, setArticles] = React.useState([])
    const [inputValue, setInputValue] = React.useState([]);
    const [loading, setLoading] = React.useState(false)
    const [themeState, setThemeState] = React.useState('select');
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    let { id } = useParams();

    const editorConfig = {
        editorState: emptyJsonEditor,
        // The editor theme
        theme: ExampleTheme,
        // Handling of errors during update
        onError(error) {
            throw error;
        },
        // Any custom nodes go here
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode
        ]
    };


    React.useEffect(() => {
        if (id) {
            ApiHelper('/articles/' + id, 'GET')
                .then( (response) => {
                    setFormData(response.data)
                    ApiHelper('/articles/' + response.data.nextArticleId, 'GET')
                        .then( response => {
                            setInputValue({id:response.data.id, label: response.data.name})
                        })
                    setLunched(true)
                })
        }
        ApiHelper('/articles', 'GET')
            .then((response) => {
                setArticles(response.data)
            })
    }, [])

    React.useEffect(() => {
        ApiHelper('/article_themes', 'GET')
            .then((response) => {
                setArticleThemes(response.data)
            })
    }, [loading])

    const formValidation = () => {
        let errorCopy = {...error}
        const formDataCopy = {...formData};
        formDataCopy.nextArticleId = inputValue.id
        formDataCopy.text = editorState
        formDataCopy.textEditor = textEditor

        for (const property in formDataCopy) {
            errorCopy[property] = formDataCopy[property] === "";
        }
        for (const property in errorCopy) {
            if (errorCopy[property] === true) {
                enqueueSnackbar('Les champs doivent être remplis', {variant: 'error'})
                break
            }
        }
        setError(errorCopy)
        handleSubmit(formDataCopy)
    }
    const handleSubmit = (e) => {
        let image = new FormData();
        image.append("file", formData.image);
        if (e.image.filePath) {
            delete e.image
            ApiHelper(id ? '/articles/' + id : '/articles', id ? 'PUT' : 'POST', e)
                .then( (response) => {
                    enqueueSnackbar('Action effectuée avec succès', {variant: 'success'});
                    navigate('/articles')
                })
                .catch( (response) => {
                    enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
                })
        } else {
            ApiHelperMedia(image)
                .then( (responseImage) => {
                    e.image = '/media_objects/' + responseImage.data.id
                    ApiHelper(id ? '/articles/' + id : '/articles', id ? 'PUT' : 'POST', e)
                        .then( (response) => {
                            enqueueSnackbar('Action effectuée avec succès', {variant: 'success'});
                            navigate('/articles')
                        })
                        .catch( (response) => {
                            enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
                        })
                })
                .catch(error => {

                })
        }
    }

    const submitTheme = () => {
        ApiHelper('/article_themes', 'POST', {name: formData.newTheme})
            .then((response) => {
                setLoading(!loading)
                setThemeState('select')
                enqueueSnackbar('Thême ajouté avec succès', {variant: 'success'});
            })
            .catch(error => {
                enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            })
    }

    const richTextChange = (e, editor) => {
        if (lunched) {
            setLunched(false)
            editor.setEditorState(editor.parseEditorState(formData.textEditor))
        }
        setTextEditor(JSON.stringify(e))
        editor.update(() => {
            setEditorState($generateHtmlFromNodes(editor, null))
        })
    }


    function MyCustomAutoFocusPlugin() {
        const [editor] = useLexicalComposerContext();

        React.useEffect(() => {
            if (lunched) {
                editor.focus();
            }
            // Focus the editor when the effect fires!
        }, [editor]);

        return null;
    }

    return <Container>
        <div style={{display: 'flex', flexDirection: 'row', marginBottom: '2em'}}>
            <TextSnippetIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                {id ? 'Editer un article' : 'Ajouter un article'}
            </Typography>
        </div>

        <div style={{margin: 8}}>

        </div>

        <Box
            component="div"
            //onSubmit={formValidation}
            sx={{
                '& .MuiTextField-root': { m: 2 },
                '& .MuiAutocomplete-root': { m: 2 },
                width: '85%',
            }}
            noValidate
            autoComplete="off"
        >
            <Grid style={{margin: "1em", display: 'flex', flexDirection: 'row'}}>
                <TextField
                    required
                    fullWidth
                    error={error.name}
                    label="Titre"
                    onChange={(e) => setFormData(() => ( {...formData, name: e.target.value} ))}
                    value={formData.name}
                />
                <TextField
                    required
                    fullWidth
                    error={error.readingTime}
                    label="Temps de lecture"
                    value={formData.readingTime}
                    onChange={(e) => setFormData(() => ( {...formData, readingTime: e.target.value} ))}
                />
                <TextField
                    required
                    fullWidth
                    disabled
                    error={error.image}
                    label="Image"
                    InputLabelProps={{shrink: true}}
                    value={formData.image instanceof File ? formData.image.name : formData.image.filePath}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton color="primary" aria-label="upload picture" component="label">
                                    <input
                                        hidden
                                        name="file"
                                        accept="image/*"
                                        type="file"
                                        onChange={ (e) => setFormData(() => ( {...formData, image: e.target.files[0]})) }
                                    />
                                    <Image />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setFormData(() => ( {...formData, image: e.target.value} ))}
                />
            </Grid>
            <Grid style={{margin: "1em", display: 'flex', flexDirection: 'row'}}>

                <div style={{width: "40%", marginTop: "auto", marginBottom: 'auto', display: 'flex'}}>
                        {
                            themeState === 'add' &&(
                                <>
                                    <TextField
                                        id="textfield-theme"
                                        required
                                        fullWidth
                                        helperText="Valider pour ajouter"
                                        //error={error.readingTime}
                                        label="Nouveau thème"
                                        value={formData.newTheme}
                                        onChange={(e) => setFormData(() => ( {...formData, newTheme: e.target.value} ))}
                                    />
                                    <IconButton color="primary" aria-label="add theme" component="label" onClick={submitTheme}>
                                        <DoneIcon />
                                    </IconButton>
                                </>
                            )
                        }
                    {
                        themeState === 'select' &&(
                             <>
                                 <TextField
                                     id="outlined-select-theme"
                                     select
                                     sx={{width: "90%"}}
                                     value={formData.articleTheme.id}
                                     label="Theme"
                                     onChange={(e) => setFormData(() => ( {...formData, articleTheme: {id: e.target.value}} ))}
                                     helperText="Cliquer sur + pour ajouter un nouveau thème"
                                 >
                                     {articleThemes.map((option, index) => (
                                         <MenuItem key={index} value={option.id}>
                                             {option.name}
                                         </MenuItem>
                                     ))}
                                 </TextField>
                                 <IconButton
                                     color="primary"
                                     aria-label="add theme"
                                     component="label"
                                     onClick={() => setThemeState('add')}
                                 >
                                     <AddIcon />
                                 </IconButton>
                             </>
                        )
                    }
                </div>
                <div style={{width: "50%"}}>
                    <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        freeSolo
                        options={articles.map(option => ({id: option.id, label: option.name}))}
                        autoHighlight
                        getOptionLabel={(option) => option.label || ""}
                        value={inputValue}
                        onChange={(event, newValue) => { setInputValue(newValue) }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{marginTop: 0}}
                                label="Article proposé"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </div>

            </Grid>
            <div style={{borderWidth: "1px"}} className={"editor-main"}>
                <LexicalComposer initialConfig={editorConfig} >
                    <div className="editor-container">
                        <ToolbarPlugin />
                        <div className="editor-inner">
                            <RichTextPlugin
                                contentEditable={<ContentEditable className="editor-input" />}
                                placeholder={<Placeholder />}
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            <HistoryPlugin />
                            <CodeHighlightPlugin />
                            <ListPlugin />
                            <LinkPlugin />
                            <OnChangePlugin onChange={(e, editor) => richTextChange(e, editor)}/>
                            <AutoLinkPlugin />
                            <MyCustomAutoFocusPlugin />
                            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                        </div>
                    </div>
                </LexicalComposer>
            </div>

            <div style={{margin: 2, display: 'flex', justifyContent: 'flex-end', width: '95%'}}>
                <Button variant="contained" onClick={formValidation}>
                    SOUMETTRE
                </Button>
            </div>
        </Box>
    </Container>
}