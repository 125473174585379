import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import {Button, Typography, IconButton, Chip} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import {useSnackbar} from "notistack";
import {DialogHelper} from "../Helper/DialogHelper";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import {DialogOfferHelper} from "../Helper/DialogOfferHelper";
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
    'https://wpliyzjisjggtailkzrz.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndwbGl5emppc2pnZ3RhaWxrenJ6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODE2NDIyNjksImV4cCI6MTk5NzIxODI2OX0.uACepR6wdiWeHcjj5f1JzQkvCA4QBSN6nH8B2TY2PI8',
    {
        auth: {
            persistSession: false,
        },
    }
);
/*TODO EDIT OFFER*/
export default function OffersList() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [dataToSend, setDataToSend] = React.useState({
        title: '',
        code: '',
        date_start: null,
        date_end: null
    });

    React.useEffect( () => {
        supabase.from('offers')
            .select()
            .order('date_start', {ascending: true})
            .then(result => {
                setData(result.data)
        })
    }, [reload])

    const handleDeleteConfirmation = (id) => {
        setIdToDelete(id);
        setOpenConfirmation(true)
    }

    const handleDelete = () => {
        supabase.from('offers')
            .delete()
            .eq('id', idToDelete)
            .then((response) => {
                if (response.status === 204) {
                    setOpenConfirmation(false);
                    enqueueSnackbar('Action effectuée avec succès', {variant: 'success'});
                    setReload(!reload)
                }
            })
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false)
    }

    const handleSend = () => {
        if (dataToSend.title === "" || dataToSend.code === "" || dataToSend.date_start === null || dataToSend.date_end === null) {
            return enqueueSnackbar('Tout les champs doivent être remplis', {variant: 'error'})
        }
        dataToSend.date_end = moment(dataToSend.date_end).subtract(1, "minute")

        supabase.from('offers')
            .insert(dataToSend)
            .then(result => {
                if (result.status === 201) {
                    setOpen(false)
                    setReload(!reload)
                    enqueueSnackbar('Action effectuée avec succès', {variant: 'success'})
                    setDataToSend({
                        title: '',
                        code: '',
                        date_start: null,
                        date_end: null
                    })
                } else {
                    enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
                }
            })
    }
    const handleSendEdit = () => {
        if (dataToSend.title === "" || dataToSend.code === "" || dataToSend.date_start === null || dataToSend.date_end === null) {
            return enqueueSnackbar('Tout les champs doivent être remplis', {variant: 'error'})
        }

        dataToSend.date_end = moment(dataToSend.date_end).subtract(1, "minute")
        console.log(dataToSend)

        supabase.from('offers')
            .update(dataToSend)
            .eq('id', dataToSend.id)
            .then(result => {
                if (result.status === 204) {
                    setOpenEdit(false)
                    setReload(!reload)
                    enqueueSnackbar('Action effectuée avec succès', {variant: 'success'})
                    setDataToSend({
                        title: '',
                        code: '',
                        date_start: null,
                        date_end: null
                    })
                } else {
                    enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
                }
            })
    }

    const handleChange = (e, name) => {
        if (name === "date_end") {
            let dateModified = new Date(e);
            dateModified.setMinutes(dateModified.getMinutes() - 1)
            setDataToSend(() => ({...dataToSend, ["date_end"]: dateModified}))
        } else {
            setDataToSend( () => ({...dataToSend, [name]: e}))
        }
    }
    const handleEdit = (data) => {
        setDataToSend(data);
        setOpenEdit(true);

    }

    const columns = [
        {
            field: 'title',
            headerName: 'Titre',
            width: 230
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 230,
        },
        {
            field: 'date_start',
            headerName: 'Date de début',
            width: 230,
            renderCell: (params) => (
                moment(params.row.date_start).format("DD-MM-YYYY HH:mm")
            )
        },
        {
            field: 'date_end',
            headerName: 'Date de fin',
            width: 230,
            renderCell: (params) => (
                moment(params.row.date_end).format("DD-MM-YYYY HH:mm")
            )
        },
        {
            field: 'on_going',
            headerName: 'En cours',
            width: 150,
            renderCell: (params) => (
                params.row.on_going ?
                    <Chip label="En cours" color="success" /> : <Chip label="Off" color="info" />
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <div style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    <IconButton color="primary" component="label" onClick={() => handleEdit(params.row)}>
                        <ModeIcon />
                    </IconButton>
                    <IconButton color="primary" component="label" onClick={() => handleDeleteConfirmation(params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            )
        },
    ];

    const handleClose = () => {
        setOpenEdit(false)
        setOpen(false)
        setDataToSend({
            title: '',
            code: '',
            date_start: null,
            date_end: null
        })
    }

    return <div>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <LocalOfferIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                Promotions
            </Typography>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: '1em'
        }}>
                <Button variant="contained" endIcon={<AddIcon />} onClick={() => setOpen(true)}>
                    Ajouter
                </Button>
        </div>
        <div style={{ display: 'flex', height: 600 }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid rows={data} columns={columns} />
            </div>
        </div>

        <DialogOfferHelper
            open={open}
            close={() => setOpen(false)}
            send={handleSend}
            data={dataToSend}
            handleChange={handleChange}
            buttonText={'Créer'}
        />

        <DialogOfferHelper
            open={openEdit}
            close={handleClose}
            send={handleSendEdit}
            data={dataToSend}
            handleChange={handleChange}
            buttonText={'Modifier'}
        />

        <DialogHelper
            open={openConfirmation}
            close={(e) => handleCloseConfirmation(e)}
            delete={handleDelete}
            title={'Voulez-vous vraiment supprimer cet utilisateur ?'}
        />
    </div>
}