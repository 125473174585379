import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import DoneIcon from '@mui/icons-material/Done';
import StorefrontIcon from '@mui/icons-material/Storefront';
import {createClient} from "@supabase/supabase-js";

const supabase = createClient(
    'https://wpliyzjisjggtailkzrz.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndwbGl5emppc2pnZ3RhaWxrenJ6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODE2NDIyNjksImV4cCI6MTk5NzIxODI2OX0.uACepR6wdiWeHcjj5f1JzQkvCA4QBSN6nH8B2TY2PI8',
    {
        auth: {
            persistSession: false,
        },
    }
);

export default function Upsell () {
    const [upsell, setUpsell] = useState({});
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect( () => {
        supabase.from('upsell').select().then(res => setUpsell(res.data[0]));
    }, [reload]);

    const handleSubmit = () => {
        setIsLoading(true)
        supabase.from('upsell').update(upsell).eq('id', 1)
            .then(res => {
                setIsLoading(false);
                setReload(!reload)
            })
    }

    return <div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 50
        }}>
            <StorefrontIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                Upsell
            </Typography>
        </div>
        {
            upsell.id === undefined ?
                <LinearProgress /> :

        <div style={{display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', marginTop: 30}}>
            <div style={{width: 300, backgroundColor: "#D0DCB7", borderRadius: 10, display: 'flex', flexDirection: 'column', padding: 10 }}>
                {
                    isLoading ?
                        <div style={{display: 'flex', margin: 'auto'}}>
                        <CircularProgress />
                        </div> :
                <>
                        <div style={{width: "100%",}}>
                    <p style={{fontWeight: 500, marginBottom: 0}}>{upsell.upsell_title}</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{width: '25%'}}>
                        <img style={{height: 70, width: 70}} src={require('../../image-sample.png')} alt={'productImage'}/>
                    </div>
                    <div style={{width: "55%"}}>
                        <p style={{marginBottom: 0}}>{upsell.product_title}</p>
                        <button style={{color: "white", borderStyle: "inherit", backgroundColor: "#2E2E28", height: 30, alignItems: 'center', marginBottom: 5, width: "100%"}}>AJOUTER</button>
                    </div>
                    <div style={{width: "20%"}}>
                        <p>{upsell.product_price} €</p>
                    </div>
                </div>
                </>
                }
            </div>

            <div style={{display: 'flex'}}>
                <div style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    gap: 15,
                    flexDirection: 'column'
                }}>
                    <div style={{gap: 15, display: 'flex'}}>
                        <TextField
                            name="product_id"
                            value={upsell.product_id}
                            onChange={(e) => setUpsell({...upsell, product_id: e.target.value})}
                            label={"ID du produit"}
                        />
                        <TextField
                            value={upsell.upsell_title}
                            onChange={(e) => setUpsell({...upsell, upsell_title: e.target.value})}
                            label={"Titre de l'upsell"}
                        />
                    </div>
                    <div style={{gap: 15, display: 'flex'}}>
                        <TextField
                            value={upsell.product_title}
                            onChange={(e) => setUpsell({...upsell, product_title: e.target.value})}
                            label={"Nom du produit"}
                        />
                        <TextField
                            value={upsell.product_price}
                            onChange={(e) => setUpsell({...upsell, product_price: e.target.value})}
                            label={"Prix du produit"}
                        />
                    </div>

                    <Button onClick={handleSubmit} variant={'contained'}>OK</Button>
                </div>
            </div>
        </div>
        }
    </div>
}