import React from 'react'
import {Box, Button, Container, Grid, TextField, Typography} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import {useSnackbar} from "notistack";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/fr';
import {DesktopDatePicker} from "@mui/x-date-pickers";
import ApiHelper from "../../Helper/ApiHelper";
import {useNavigate, useParams} from 'react-router-dom';

export default function UserForm () {
    const [formData, setFormData] = React.useState(!id ? {
        firstName: '',
        lastName: '',
        dateOfBirth: new Date(),
        email: '',
        password: '',
        passwordConfirm: '',
        pushToken: 'push'
    } : {
        firstName: '',
        lastName: '',
        dateOfBirth: new Date(),
        email: '',
        }
    );
    const [error, setError] = React.useState(!id ? {
        firstName: false,
        lastName: false,
        dateOfBirth: false,
        email: false,
        password: false,
        passwordConfirm: false
    } : {
        firstName: false,
        lastName: false,
        dateOfBirth: false,
        email: false,
    });
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    let { id } = useParams();

    React.useEffect(() => {
        if (id) {
            ApiHelper('/users/' + id, 'GET')
                .then( (response) => {
                    setFormData(response.data)
                })
        }
    }, [])

    const formValidation = (e) => {
        let errorCopy = {...error}
        for (const property in formData) {
            errorCopy[property] = formData[property] === "";
        }
        for (const property in errorCopy) {
           if (errorCopy[property] === true) {
               enqueueSnackbar('Les champs doivent être remplis', {variant: 'error'})
               break
           }
        }
        setError(errorCopy)
        if (!id) {
            if (formData.password !== formData.passwordConfirm) {
                setError(() => ({...error, password: true}))
                setError(() => ({...error, passwordConfirm: true}))
                return enqueueSnackbar('Les mots de passes doivent être similaires', {variant: 'error'})
            }
        }
        handleSubmit(e)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const formDataCopy = {...formData};
        formDataCopy.dateOfBirth = formData.dateOfBirth['$d'];
        ApiHelper(id ? '/users/' + id : '/users', id ? 'PUT' : 'POST', formData)
            .then( (response) => {
                enqueueSnackbar('Action effectuée avec succès', {variant: 'success'});
                navigate('/users')
            })
            .catch( (response) => {
                enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            })
    }

    const handleChange = (e, index) => {

        //console.log(e.target.value)
        //setFormData(() => ( {...formData, : e.target.value} ))
    }

    return <Container>
        <div style={{display: 'flex', flexDirection: 'row', marginBottom: '2em'}}>
            <PeopleIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                {id ? 'Editer un utilisateur' : 'Ajouter un utilisateur'}
            </Typography>
        </div>

        <Box
            component="form"
            onSubmit={formValidation}
            sx={{
                '& .MuiTextField-root': { m: 2 },
                width: '60%',
            }}
            noValidate
            autoComplete="off"
        >
            <Grid style={{margin: "1em", display: 'flex', flexDirection: 'row'}}>
                <TextField
                    required
                    fullWidth
                    error={error.lastName}
                    label="Nom"
                    onChange={(e) => setFormData(() => ( {...formData, lastName: e.target.value} ))}
                    value={formData.lastName}
                />
                <TextField
                    required
                    fullWidth
                    error={error.firstName}
                    label="Prénom"
                    value={formData.firstName}
                    onChange={(e) => setFormData(() => ( {...formData, firstName: e.target.value} ))}

                />
            </Grid>
            <Grid style={{margin: "1em", display: 'flex', flexDirection: 'row'}}>
                <TextField
                    required
                    fullWidth
                    error={error.email}
                    label="Email"
                    value={formData.email}
                    onChange={(e) => setFormData(() => ( {...formData, email: e.target.value} ))}

                />
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                    <DesktopDatePicker
                        mask={'__/__/____'}
                        label="Date de naissance"
                        value={formData.dateOfBirth}
                        onChange={(newValue) => {
                            setFormData(() => ( {...formData, dateOfBirth: newValue}))
                        }}
                        renderInput={(params) => <TextField fullWidth error={error.dateOfBirth} {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            {
                !id ?
                    <Grid style={{margin: "1em", display: 'flex', flexDirection: 'row'}}>
                        <TextField
                            required
                            fullWidth
                            error={error.password}
                            label="Mot de passe"
                            autoComplete={'off'}
                            type={'password'}
                            value={formData.password}
                            onChange={(e) => setFormData(() => ( {...formData, password: e.target.value} ))}
                        />
                        <TextField
                            required
                            fullWidth
                            error={error.passwordConfirm}
                            label="Confirmation"
                            autoComplete={'off'}
                            type={'password'}
                            value={formData.passwordConfirm}
                            onChange={(e) => setFormData(() => ( {...formData, passwordConfirm: e.target.value} ))}
                        />
                    </Grid> : null
            }

            <div style={{margin: 2, display: 'flex', justifyContent: 'flex-end', width: '95%'}}>
                <Button variant="contained" onClick={formValidation}>
                    SOUMETTRE
                </Button>
            </div>
        </Box>

    </Container>
}