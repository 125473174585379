import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {Link, Outlet, useLocation} from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ErrorIcon from '@mui/icons-material/Error';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleIcon from '@mui/icons-material/People';
import {AuthContext} from "../context/AuthContextProvider";
import StorefrontIcon from '@mui/icons-material/Storefront';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import RanLogo from '../ran-logo.svg';

const drawerWidth = 240;

export default function Root() {
    const auth = React.useContext(AuthContext);
    const {pathname} = useLocation();

    const navsideLinks = [
        {
            title: 'Dashboard',
            link: '/dashboard',
            icon: DashboardIcon,
        },
        {
            title: 'Articles',
            link: '/articles',
            icon: TextSnippetIcon,
        },
        {
            title: 'Notifications',
            link: '/notifications',
            icon: NotificationsIcon,
        },
        {
            title: 'Utilisateurs',
            link: '/users',
            icon: PeopleIcon,
        },
        {
            title: 'Promotions',
            link: '/offers',
            icon: LocalOfferIcon,
        },
        {
            title: 'Logs',
            link: '/logs',
            icon: ErrorIcon,
        },
        {
            title: 'Upsell',
            link: '/upsell',
            icon: StorefrontIcon,
        },
        {
            title: 'Parrainage',
            link: '/sponsorship',
            icon: CardGiftcardIcon,
        },
        /*{
            title: 'Paramètres',
            link: '/settings',
            icon: SettingsIcon,
        }*/
    ]

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
            >
                <Toolbar>

                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar style={{display: 'flex', padding: 0}}>
                    <img
                        src={"https://leretouraunaturel.com/img/logo-1670437451.jpg"}
                        alt="ran logo"
                        style={{
                            height: 60,
                        }}
                    />
                </Toolbar>
                <Divider />
                <List>
                    {navsideLinks.map( (item, index) => (
                            <Link to={item.link} style={{textDecoration: 'none', width: "100%", color: 'rgba(0, 0, 0, 0.87)'}}>
                                <ListItemButton key={index} disablePadding selected={pathname === item.link}>
                                    <ListItemIcon>
                                        <item.icon/>
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} />
                                </ListItemButton>
                            </Link>
                    ))}
                    <ListItem disablePadding>
                            <ListItemButton onClick={() => auth.logout()}>
                                <ListItemIcon>
                                    <PowerSettingsNewIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"Déconnexion"} />
                            </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
            </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, marginTop: "4em" }}
            >
                <Outlet/>
            </Box>
        </Box>
    );
}
