import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, TextField,
} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDateTimePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

export const DialogPushHelper = ({open, close, send, data, handleChange, toPlan }) => {

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                fullWidth
                maxWidth={'md'}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{backgroundColor: '#CCCC'}} id="alert-dialog-title">
                    {"Notification push"}
                </DialogTitle>
                <DialogContent style={{marginTop: 8, width: '60%', marginRight:'auto', marginLeft: 'auto'}} >
                    <TextField
                        required
                        fullWidth
                        label="Titre"
                        margin={"dense"}
                        value={data.title}
                        onChange={(e) => handleChange(e.target.value, 'title')}
                    />
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        margin={"dense"}
                        label="Texte"
                        value={data.description}
                        onChange={(e) => handleChange(e.target.value, 'description')}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                        <DesktopDateTimePicker
                            mask={'__/__/____'}
                            label="Planifier l'envoie"
                            value={data.sendAt}
                            onChange={(newValue) => handleChange(newValue, 'sendAt') }
                            renderInput={(params) => <TextField helperText={"Attention, l'heure choisie doit impérativement être de demie-heure en demie-heure"} fullWidth margin={"dense"} {...params} />}
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary">
                        annuler
                    </Button>
                    <Button onClick={toPlan} color="primary" variant="contained" autoFocus>
                        Plannifier
                    </Button>
                    <Button onClick={send} color="primary" variant="contained" autoFocus>
                        Envoi immédiat
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}