import './App.css';
import React from 'react'
import {SnackbarProvider} from "notistack";
import AuthContextProvider from "./context/AuthContextProvider";
import RouterManagerProvider from "./manager/RouterManagerProvider";
import {createTheme, ThemeProvider} from "@mui/material";
import {decode, encode} from 'base-64'

if (!global.btoa) {
    global.btoa = encode;
}

if (!global.atob) {
    global.atob = decode;
}

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                light: '#BF9105',
                main: '#BF9105',
                dark: '#BF9105',
                contrastText: '#fff',
            },
            secondary: {
                light: 'rgba(191,145,5,0.1)',
                main: 'rgba(191,145,5,0.1)',
                dark: 'rgba(191,145,5,0.1)',
                contrastText: '#000',
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
        <SnackbarProvider>
            <AuthContextProvider>
                <RouterManagerProvider/>
            </AuthContextProvider>
        </SnackbarProvider>
        </ThemeProvider>
  );
}

export default App;
