import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import {Button, Typography, IconButton} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ApiHelper from "../Helper/ApiHelper";
import ModeIcon from '@mui/icons-material/Mode';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useSnackbar} from "notistack";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {Link, useNavigate} from "react-router-dom";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import {DialogListSponsorship, DialogSponsorship} from "../Helper/DialogSponsorship";

export default function Sponsorship() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = React.useState([]);
    const [affiliates, setAffiliates] = React.useState([]);
    const [reload, setReload] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [openConfirmation1, setOpenConfirmation1] = React.useState(false);
    const [userIdToEdit, setUserIdToEdit] = React.useState(null);
    const [code, setCode] = React.useState("");


    React.useEffect( () => {
        ApiHelper('/users', 'GET')
            .then( (response) => {
                setData(response.data)
            })
    }, [reload])

    const handleDeleteConfirmation = (value) => {
        setUserIdToEdit(value);
        setOpenConfirmation(true)
    }

    const handleDeleteConfirmation1 = (affiliationId) => {
        setOpenConfirmation1(true)
        ApiHelper(`/affiliations/${affiliationId}`, 'GET')
            .then( (response) => {
                setAffiliates(response.data.affiliates)
            })
    }

    const handleDelete = () => {
        let user = data.find(item => item.id === userIdToEdit);
        user.affiliation = {code: code};
        ApiHelper('/users/' + userIdToEdit, 'PUT', user)
            .then( (response) => {
                setOpenConfirmation(false)
                setReload(!reload)
                enqueueSnackbar('action effectuée avec succès', {variant: 'success'})
            })
            .catch((response) => {
                enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            })
    }

    const handleDelete1 = () => {
        ApiHelper('/users/' + userIdToEdit, 'DELETE')
            .then( (response) => {
                setOpenConfirmation(false)
                setReload(!reload)
                enqueueSnackbar('action effectuée avec succès', {variant: 'success'})
            })
            .catch((response) => {
                enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            })
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false)
    }

    const handleChangeCode = (value) => {
        setCode(value)
    }

    const handleCloseConfirmation1 = () => {
        setOpenConfirmation1(false)
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Nom',
            width: 230,
            renderCell: (params) => (
                params.row.firstName + ' ' + params.row.lastName
            )
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 230,
        },
        {
            field: 'sponsorshipCode',
            headerName: 'Code',
            width: 230,
            renderCell: params => (
                <div style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    {
                        !params.row.affiliation ? "Aucun code attribué" : params.row.affiliation.code
                    }
                </div>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <div style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    {
                        !params.row.affiliation ? <IconButton color="primary" component="label" onClick={() => handleDeleteConfirmation(params.row.id)}>
                            <AddCircleIcon />
                        </IconButton> : <IconButton color="primary" component="label" onClick={() => handleDeleteConfirmation1(params.row.affiliation.id)}>
                            <RemoveRedEyeIcon />
                        </IconButton>
                    }

                </div>
            )
        },
    ];

    return <div>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <CardGiftcardIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                Parrainage
            </Typography>
        </div>
        <div style={{ display: 'flex', height: 400, marginTop: 50 }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid rows={data} columns={columns} />
            </div>
        </div>

        <DialogSponsorship
            open={openConfirmation}
            handleChangeCode={handleChangeCode}
            code={code}
            close={(e) => handleCloseConfirmation(e)}
            delete={handleDelete}
            title={'Voulez-vous vraiment supprimer cet utilisateur ?'}
        />

        <DialogListSponsorship
            open={openConfirmation1}
            close={(e) => handleCloseConfirmation1(e)}
            users={affiliates}
            title={'Liste des users'}
        />
    </div>
}