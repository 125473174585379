import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import {useSnackbar} from "notistack";
import {Link, useNavigate} from "react-router-dom";
import ApiHelper from "../Helper/ApiHelper";
import moment from "moment";
import {Button, IconButton, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {DialogHelper} from "../Helper/DialogHelper";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

export default function Article() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = React.useState([]);
    const [reload, setReload] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect( () => {
        ApiHelper('/articles', 'GET')
            .then( (response) => {
                setData(response.data)
            })
    }, [reload])

    const handleDeleteConfirmation = (id) => {
        setIdToDelete(id);
        setOpenConfirmation(true)
    }

    const handleDelete = () => {
        ApiHelper('/articles/' + idToDelete, 'DELETE')
            .then( (response) => {
                setOpenConfirmation(false)
                setReload(!reload)
                enqueueSnackbar('action effectuée avec succès', {variant: 'success'})
            })
            .catch((response) => {
                enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            })
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Nom',
            width: 230,
        },
        {
            field: 'readingTime',
            headerName: 'Temps de lecture',
            width: 230,
        },
        {
            field: 'articleTheme',
            headerName: 'Thême',
            width: 230,
            renderCell: (params) => (
                params.row.articleTheme.name
            )
        },
        {
            field: 'createdAt',
            headerName: 'Crée le ',
            width: 230,
            renderCell: (params) => (
                moment(params.row.createdAt).format("DD-MM-YYYY")
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <div style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    <IconButton color="primary" component="label" onClick={() => navigate('/articles/edit/' + params.row.id)}>
                        <ModeIcon />
                    </IconButton>
                    <IconButton color="primary" component="label" onClick={() => handleDeleteConfirmation(params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            )
        },
    ];


    return <div>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <TextSnippetIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                Articles
            </Typography>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: '1em'
        }}>
            <Link to={'/articles/create'} style={{ color: "inherit", textDecoration: "inherit" }}>
                <Button variant="contained" endIcon={<AddIcon />}>
                    Ajouter
                </Button>
            </Link>

        </div>
        <div style={{ display: 'flex', height: 400 }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid rows={data} columns={columns} />
            </div>
        </div>

        <DialogHelper
            open={openConfirmation}
            close={() => setOpenConfirmation(false)}
            delete={handleDelete}
            title={'Voulez-vous vraiment supprimer cet article ?'}
        />
    </div>
}