import React from "react";
import {useSnackbar} from "notistack";
import ApiHelper from "../Helper/ApiHelper";
import {Box, Button, IconButton, TextField, Typography} from "@mui/material";
import {DialogHelper} from "../Helper/DialogHelper";
import DashboardIcon from '@mui/icons-material/Dashboard';
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts'
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';
import moment from "moment";
import ApexCharts from "../Charts/ApexCharts";

export default function Dashboard() {
    const { enqueueSnackbar } = useSnackbar();
    const [dataPushes, setDataPushes] = React.useState([]);
    const [dataSessions, setDataSessions] = React.useState([]);
    const [registrationData, setRegistrationData] = React.useState([]);
    const [ordersData, setOrdersData] = React.useState([]);
    const [gt, setGt] = React.useState(null);
    const [lt, setLt] = React.useState(null);
    const [reload, setReload] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(null);

    React.useEffect( () => {
        ApiHelper('/kpi?kind=sessions', 'GET')
            .then( (response) => {
                setDataSessions(response.data)
            })
        ApiHelper('/kpi?kind=pushes', 'GET')
            .then( (response) => {
                setDataPushes(response.data)
            })
        ApiHelper('/kpi?kind=registration', 'GET')
            .then( (response) => {
                setRegistrationData(response.data)
            })
        ApiHelper('/kpi?kind=orders', 'GET')
            .then( (response) => {
                setOrdersData(response.data)
            })
    }, [reload])

    const handleDelete = () => {
        ApiHelper('/articles/' + idToDelete, 'DELETE')
            .then( (response) => {
                setOpenConfirmation(false)
                setReload(!reload)
                enqueueSnackbar('action effectuée avec succès', {variant: 'success'})
            })
            .catch((response) => {
                enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            })
    }

    const searchHandle = () => {
        ApiHelper('/kpi?kind=sessions&gt=' + moment(gt['$d']).format('YYYY-MM-DD') + '&lt=' + moment(lt['$d']).format('YYYY-MM-DD'), 'GET')
            .then( (response) => {
                setDataSessions(response.data)
            })
        ApiHelper('/kpi?kind=pushes&gt=' + moment(gt['$d']).format('YYYY-MM-DD') + '&lt=' + moment(lt['$d']).format('YYYY-MM-DD'), 'GET')
            .then( (response) => {
                setDataPushes(response.data)
            })
    }

    const handleInitialize = () => {
        setReload(!reload)
        setGt(null)
        setLt(null)
    }

    const options = {
        title: {
            text: 'Push & Sessions'
        },
        chart: {
            type: 'spline'
        },
        xAxis: {
            type: "datetime",
            labels: {
                formatter: function () {
                    return Highcharts.dateFormat('%e %b %Y', this.value)
                }
            }
        },
        yAxis: [{
            title: {
                text: ''
            },
            startOnTick: true,
            endOnTick: true,
        }],
        series: [
            {
                name: 'Sessions',
                data: dataSessions,
                //color: 'red'
            },
            {
                name: 'Notifications',
                data: dataPushes,
                //color: 'blue'
            },

        ]
    }


    return <div>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <DashboardIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                Dashboard
            </Typography>
        </div>

        <div style={{width: "100%"}}>
            <ApexCharts registrationData={registrationData} ordersData={ordersData}/>
        </div>

        <Box sx={{
            '& .MuiTextField-root': { m: 2 },
            display: 'flex',
            marginTop: '1em',
            marginBottom: '1em'
        }} >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                <DesktopDatePicker
                    id={"gt"}
                    style={{margin: '1em'}}
                    mask={'__/__/____'}
                    label="À partir du"
                    value={gt}
                    onChange={(newValue) => {
                        setGt(() => ( newValue ))
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                <DesktopDatePicker
                    id={"lt"}
                    mask={'__/__/____'}
                    label="Jusqu'au"
                    value={lt}
                    onChange={(newValue) => {
                        setLt(() => ( newValue ))
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>
            <div style={{marginTop: 'auto', marginBottom: 'auto', marginRight: "0.5em"}}>
                <Button variant="contained" disabled={gt === null || lt === null} endIcon={<SearchIcon />} onClick={searchHandle}>
                    Rechercher
                </Button>
            </div>
            <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                <IconButton variant="contained" onClick={handleInitialize}>
                    <CachedIcon />
                </IconButton>
            </div>
        </Box>

        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>


        <DialogHelper
            open={openConfirmation}
            close={() => setOpenConfirmation(false)}
            delete={handleDelete}
            title={'Voulez-vous vraiment supprimer cet article ?'}
        />
    </div>
}