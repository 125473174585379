import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton,
    TextField,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import SyncIcon from '@mui/icons-material/Sync';

export const DialogSponsorship = (props) => {
    const handleCodeGenerate = () => {
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let resultat = '';
        const longueurCaracteres = caracteres.length;
        for ( let i = 0; i < 6; i++ ) {
            resultat += caracteres.charAt(Math.floor(Math.random() * longueurCaracteres));
        }
        props.handleChangeCode(resultat);
    }

    return (
        <div>
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{backgroundColor: '#CCCC'}} id="alert-dialog-title">
                    {"Code"}
                </DialogTitle>
                <DialogContent style={{marginTop: 8}}>
                    <TextField
                        onChange={(e) => props.handleChangeCode(e.target.value)}
                        value={props.code}
                        InputProps={{
                            endAdornment: <IconButton onClick={handleCodeGenerate}><SyncIcon/></IconButton>
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary">
                        annuler
                    </Button>
                    <Button onClick={props.delete} color="primary" variant="contained" autoFocus>
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export const DialogListSponsorship = (props) => {

    return (
        <div>
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle style={{backgroundColor: '#CCCC'}} id="alert-dialog-title">
                    Liste des parrainés | Total :  {props.users.length}
                </DialogTitle>
                <DialogContent style={{marginTop: 8}}>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {props.users.map((value) => (
                            <ListItem
                                key={value}
                                disableGutters
                                style={{ borderBottom: "1px solid"}}
                            >
                                <ListItemText primary={`${value.email} | ${value.firstName} ${value.lastName}`} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary">
                        annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}