import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal,
    Typography
} from "@mui/material";
import moment from "moment";
import ErrorIcon from "@mui/icons-material/Error";
import ApiHelper from "../Helper/ApiHelper";

export default function LogList() {
    const [data, setData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleOpen = (value) => {
        setMessage(value);
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    React.useEffect( () => {
        ApiHelper('/logs', 'GET')
            .then( (response) => {
                setData(response.data)
            })
    }, [])

    const columns = [
        {
            field: 'message',
            headerName: 'Message',
            width: 600,
            renderCell: params => (
                <p onClick={() => handleOpen(params.row.message)}> {params.row.message}</p>
            )
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 230,
        },
        {
            field: 'occuredAt',
            headerName: "Date d'occurence",
            width: 230,
            renderCell: (params) => (
                moment(params.row.occuredAt).format("DD-MM-YYYY HH:mm")
            )
        }
    ];

    return <div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: "1em"
        }}>
            <ErrorIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                Logs
            </Typography>
        </div>
        <div style={{ display: 'flex', height: 600 }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid rows={data} columns={columns} />
            </div>
        </div>

        {/*<Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Message
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {message}
                </Typography>
            </Box>
        </Modal>*/}

        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{backgroundColor: '#CCCC'}} id="alert-dialog-title">
               Message
            </DialogTitle>
            <DialogContent style={{marginTop: 8}}>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    </div>
}