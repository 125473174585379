import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

export const DialogHelper = (props) => {

    return (
        <div>
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{backgroundColor: '#CCCC'}} id="alert-dialog-title">
                    {"Confirmation"}
                </DialogTitle>
                <DialogContent style={{marginTop: 8}}>
                    <DialogContentText id="alert-dialog-description">
                        {props.title}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary">
                        Non
                    </Button>
                    <Button onClick={props.delete} color="primary" variant="contained" autoFocus>
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}