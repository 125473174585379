import React from 'react';
import {AuthContext} from "../context/AuthContextProvider";
import {RouterProvider} from "react-router-dom";
import {routerNotAuthenticated, routerAuthenticated} from "../components/BrowserRouter";
import {useJwt} from "react-jwt";

export default function RouterManagerProvider () {
    const auth = React.useContext(AuthContext);
    const { isExpired } = useJwt(auth.token);

    return <RouterProvider router={auth.isLoggedIn && !isExpired ? routerAuthenticated : routerNotAuthenticated}/>
    //return <RouterProvider router={routerAuthenticated}/>
}