import axios from 'axios';

/*With this function, you'll be able to call the api, first you choose your route, then the method (GET, POST, PUT...)
* then the data if needed. You don't have to specify any token or header, all thing is made during authentication  */
const ApiHelper = async (route, method, data = null, pagination = false) => {

    return axios({
        method: method,
        url: 'https://ran.ovh' + route,
        data: data,
        headers: pagination ? {
            'authorization': `Bearer ${localStorage.getItem("token")}`
        } : {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${localStorage.getItem("token")}`
        }
    })
}

export const ApiLoginHelper = async (data) => {
    return axios({
        method: "POST",
        url: 'https://ran.ovh/authentication_token',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export const ApiHelperMedia = async (data) => {

    return axios({
        method: 'POST',
        url: 'https://ran.ovh/media_objects',
        data: data,
        headers: {
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'authorization': `Bearer ${localStorage.getItem("token")}`
        }
    })
}

export const PasswordForgottenHelper = async (data) => {

    return axios({
        method: 'POST',
        url: 'https://ran.ovh/password-forgotten',
        data: data,
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}

export default ApiHelper;