import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import ApiHelper from "../Helper/ApiHelper";
import {Button, Chip, IconButton, Typography} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import {DialogPushHelper} from "../Helper/DialogPushHelper";
import {useSnackbar} from "notistack";
import CancelIcon from '@mui/icons-material/Cancel';
import {DialogHelper} from "../Helper/DialogHelper";
export default function Notification() {
    const [data, setData] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [dataToSend, setDataToSend] = React.useState({
        title: '',
        description: '',
        sendAt: null
    });
    const [loading, setLoading] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    React.useEffect( () => {
        ApiHelper('/pushes?order[createdAt]=desc', 'GET')
            .then( (response) => {
                setData(response.data)
            })
    }, [loading])

    const handleDelete = () => {
        ApiHelper('/pushes/' + idToDelete, 'DELETE')
            .then( (response) => {
                setOpenConfirmation(false)
                setLoading(!loading)
                enqueueSnackbar('action effectuée avec succès', {variant: 'success'})
            })
            .catch((response) => {
                enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            })
    }

    const handleDeleteConfirmation = (id) => {
        setIdToDelete(id);
        setOpenConfirmation(true)
    }

    const columns = [
        {
            field: 'title',
            headerName: 'Titre',
            width: 230,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 230,
        },
        {
            field: 'delivered',
            headerName: 'Délivrée',
            width: 150,
            renderCell: (params) => (
                params.row.delivered ?
                <Chip label="success" color="success" /> : <Chip label="En attente" color="warning" />
            )
        },
        {
            field: 'sendedCount',
            headerName: "Nombre d'envois",
            width: 150,
        },
        {
            field: 'createdAt',
            headerName: "Crée le",
            width: 180,
            renderCell: (params) => (
                moment(params.row.createdAt).format("DD-MM-YYYY")
            )
        },
        {
            field: 'sendAt',
            headerName: "À envoyer le",
            width: 190,
            renderCell: (params) => (
                params.row.sendAt ? moment(params.row.sendAt).format("DD-MM-YYYY HH:mm") : null
            )
        },
        {
            field: 'action',
            headerName: "Actions",
            width: 230,
            renderCell: (params) => (
                params.row.delivered ? null :
                <IconButton onClick={() => handleDeleteConfirmation(params.row.id)}>
                    <CancelIcon/>
                </IconButton>
            )
        },
    ];

    const handleSend = () => {
        if (dataToSend.subject === "") {
            return enqueueSnackbar('La notification doit contenir un titre', {variant: 'error'})
        }

        ApiHelper('/push_notification', 'POST', dataToSend)
            .then( (response) => {
                setOpen(false)
                setLoading(!loading)
                enqueueSnackbar('Action effectuée avec succès', {variant: 'success'})
            })
            .catch( error => { enqueueSnackbar('Une erreur est survenue', {variant: 'error'}) })
    }

    const handleToPlan = () => {
        if (dataToSend.subject === "") {
            return enqueueSnackbar('La notification doit contenir un titre', {variant: 'error'})
        }
        if (dataToSend.sendAt === null) {
            return enqueueSnackbar('La notification doit être planifiée', {variant: 'error'})
        }
        const data = {...dataToSend}
        data.delivered = false

        ApiHelper('/pushes', 'POST', data)
            .then( (response) => {
                setOpen(false)
                setLoading(!loading)
                enqueueSnackbar('Action effectuée avec succès', {variant: 'success'})
            })
            .catch( error => { enqueueSnackbar('Une erreur est survenue', {variant: 'error'}) })
    }

    const handleChange = (e, name) => {
        setDataToSend( () => ({...dataToSend, [name]: e}))
    }

    return <div>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <NotificationsIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                Notifications
            </Typography>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: '1em'
        }}>
            <Button variant="contained" endIcon={<AddIcon />} onClick={() => setOpen(true)}>
                Ajouter
            </Button>

        </div>
        <div style={{ display: 'flex', height: 400 }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid rows={data} columns={columns} />
            </div>
        </div>

        <DialogPushHelper
            open={open}
            close={() => setOpen(false)}
            send={handleSend}
            data={dataToSend}
            toPlan={handleToPlan}
            handleChange={handleChange}
        />

        <DialogHelper
            open={openConfirmation}
            close={() => setOpenConfirmation(false)}
            delete={handleDelete}
            title={'Voulez-vous vraiment annuler cette notification ?'}
        />
    </div>
}