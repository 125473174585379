import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    Navigate
} from "react-router-dom";
import Root from "../Root";
import Dashboard from "../Dashboard";
import React from "react";
import Settings from "../Settings";
import Article from "../Article";
import Notification from "../Notification";
import User from "../User";
import UserForm from "../User/form";
import ArticleForm from "../Article/form";
import Login from "../Login/Login";
import OffersList from "../Offers/OffersList";
import LogList from "../Logs/LogList";
import Upsell from "../Upsell";
import Sponsorship from "../Sponsorship";


export const routerAuthenticated = createBrowserRouter(
    [
        {
            path: "/",
            element: <Root/>,
            children: [
                {
                    index: true,
                    element: <Dashboard/>
                },
                {
                    path: '/settings',
                    element: <Settings/>
                },
                {
                    path: "/users",
                    element: <User/>
                },
                {
                    path: "users/create",
                    element: <UserForm/>
                },
                {
                    path: "users/edit/:id",
                    element: <UserForm/>
                },
                {
                    path: "/articles",
                    element: <Article/>
                },
                {
                    path: "articles/create",
                    element: <ArticleForm/>
                },
                {
                    path: "articles/edit/:id",
                    element: <ArticleForm/>
                },
                {
                    path: "notifications",
                    element: <Notification/>
                },
                {
                    path: "offers",
                    element: <OffersList/>
                },
                {
                    path: "logs",
                    element: <LogList/>
                },
                {
                    path: "upsell",
                    element: <Upsell/>
                },
                {
                    path: "sponsorship",
                    element: <Sponsorship/>
                },

            ]
        },
        {
            path: "*",
            element: <Navigate to={'/'} replace/>
        }
    ]);

export const routerNotAuthenticated = createBrowserRouter(
    [
        {
            path: "/",
            element: <Login />,
        },
        {
            path: "*",
            element: <Navigate to="/" />,
        }
    ]
);