import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, TextField,
} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDateTimePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

export const DialogOfferHelper = ({open, close, send, data, handleChange, buttonText }) => {

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                fullWidth
                maxWidth={'md'}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{backgroundColor: '#CCCC'}} id="alert-dialog-title">
                    {"Promotions"}
                </DialogTitle>
                <DialogContent style={{marginTop: 8, width: '60%', marginRight:'auto', marginLeft: 'auto'}} >
                    <TextField
                        required
                        fullWidth
                        label="Titre"
                        margin={"dense"}
                        value={data.title}
                        onChange={(e) => handleChange(e.target.value, 'title')}
                    />
                    <TextField
                        fullWidth
                        multiline
                        margin={"dense"}
                        label="Code"
                        required
                        value={data.code}
                        onChange={(e) => handleChange(e.target.value, 'code')}
                    />
                    <div style={{display: 'flex', gap: "5px"}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                            <DesktopDateTimePicker
                                mask={'__/__/____'}
                                label="Date de début"
                                value={data.date_start}
                                onChange={(newValue) => handleChange(newValue, 'date_start') }
                                renderInput={(params) => <TextField helperText={"Attention, l'heure choisie doit impérativement être de demie-heure en demie-heure"} fullWidth margin={"dense"} {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                            <DesktopDateTimePicker
                                mask={'__/__/____'}
                                label="Date de fin"
                                value={data.date_end}
                                onChange={(newValue) => handleChange(newValue, 'date_end') }
                                renderInput={(params) => <TextField helperText={"Attention, l'heure choisie doit impérativement être de demie-heure en demie-heure"} fullWidth margin={"dense"} {...params} />}
                            />
                        </LocalizationProvider>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary">
                        annuler
                    </Button>
                    <Button onClick={send} color="primary" variant="contained" autoFocus>
                        {buttonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}