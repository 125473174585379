import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import {Button, Typography, IconButton} from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";
import ApiHelper from "../Helper/ApiHelper";
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import {useSnackbar} from "notistack";
import {DialogHelper} from "../Helper/DialogHelper";
import {Link, useNavigate} from "react-router-dom";

export default function User() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = React.useState([]);
    const [reload, setReload] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect( () => {
        ApiHelper('/users', 'GET')
            .then( (response) => {
                setData(response.data)
            })
    }, [reload])

    const handleDeleteConfirmation = (id) => {
        setIdToDelete(id);
        setOpenConfirmation(true)
    }

    const handleDelete = () => {
        ApiHelper('/users/' + idToDelete, 'DELETE')
            .then( (response) => {
                setOpenConfirmation(false)
                setReload(!reload)
                enqueueSnackbar('action effectuée avec succès', {variant: 'success'})
            })
            .catch((response) => {
                enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            })
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false)
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Nom',
            width: 230,
            renderCell: (params) => (
                params.row.firstName + ' ' + params.row.lastName
            )
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 230,
        },
        {
            field: 'pushToken',
            headerName: 'Token',
            width: 230,
        },
        {
            field: 'dateOfBirth',
            headerName: 'Date de naissance',
            width: 230,
            renderCell: (params) => (
                moment(params.row.dateOfBirth).format("DD-MM-YYYY")
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <div style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    <IconButton color="primary" component="label" onClick={() => navigate('/users/edit/' + params.row.id)}>
                        <ModeIcon />
                    </IconButton>
                    <IconButton color="primary" component="label" onClick={() => handleDeleteConfirmation(params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            )
        },
    ];

    return <div>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <PeopleIcon style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}/>
            <Typography variant="h4" gutterBottom style={{marginTop: 'auto', marginBottom: 'auto'}}>
                Utilisateurs
            </Typography>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: '1em'
        }}>
            <Link to={'/users/create'} style={{ color: "inherit", textDecoration: "inherit" }}>
                <Button variant="contained" endIcon={<AddIcon />}>
                    Ajouter
                </Button>
            </Link>

        </div>
        <div style={{ display: 'flex', height: 400 }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid rows={data} columns={columns} />
            </div>
        </div>

        <DialogHelper
            open={openConfirmation}
            close={(e) => handleCloseConfirmation(e)}
            delete={handleDelete}
            title={'Voulez-vous vraiment supprimer cet utilisateur ?'}
        />
    </div>
}