import React from 'react';
import Chart from "react-apexcharts";
import './chart.css'

let colorPalette = ['#00D8B6','#008FFB',  '#FEB019', '#FF4560', '#775DD0'];
export default function ApexCharts({registrationData, ordersData}) {
    const bar = {
        options: {
            chart: {
                type: 'bar',
                height: 380,
                width: '100%',
                stacked: true,
            },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                }
            },
            colors: colorPalette,
            xaxis: {
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    style: {
                        colors: '#78909c'
                    },
                    formatter: function(val, index) {
                        return val.toFixed(2);
                    }
                },
            },
            tooltip: {
                y: {
                    formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                        return value
                    }
                },
            },
            title: {
                text: 'Ventes mensuelles',
                align: 'left',
                style: {
                    fontSize: '18px'
                }
            }
        },
        series: [
            {
                name: "Ventes",
                data: ordersData,
            }
        ]
    };

    const lines = {
        options: {
            chart: {
                height: 340,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            plotOptions: {
                stroke: {
                    width: 4,
                    curve: 'smooth'
                },
            },
            colors: colorPalette,
            title: {
                floating: false,
                text: 'Comptes crées',
                align: 'left',
                style: {
                    fontSize: '18px'
                }
            },
            subtitle: {
                text: registrationData.total,
                align: 'center',
                margin: 30,
                offsetY: 40,
                style: {
                    color: '#222',
                    fontSize: '24px',
                }
            },
            tooltip: {
                y: {
                    formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                        return value
                    }
                },
            },
            markers: {
                size: 0
            },

            grid: {

            },
            xaxis: {
                labels: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                tickAmount: 2,
                labels: {
                    show: false,
                    formatter: function(val, index) {
                        return val ? val.toFixed(2) : null
                    }
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false
                },
                min: 0,

            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetY: -20,
                offsetX: -30
            }
        },
        series: [
            {
                name: "Inscriptions",
                data: registrationData.data ? registrationData.data : []
            }
        ],
    }

    return (
        <div style={{display: 'flex', justifyContent: 'space-around', gap: "2em", marginTop: "2em"}}>
            <div style={{width: '50%'}}>
                <div className="box">
                    <div id="bar">
                        <Chart options={bar.options} type={'bar'} series={bar.series} width={500} />
                    </div>
                </div>
            </div>
            <div style={{width: "50%"}}>
                <div className="box">
                    <div id="line">
                        <Chart options={lines.options} series={lines.series} width={500} />
                    </div>
                </div>
            </div>
        </div>

    )
}



export function ApexChartBar() {
    const series = {
        series: [{
            name: 'PRODUCT A',
            data: [44, 55]
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: 'normal'
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            xaxis: {
                categories: [
                    '2012 Q3', '2012 Q4'
                ],
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'right',
                offsetX: 0,
                offsetY: 50
            },
        },
    };

    return (
        <Chart options={series.options} series={series.series} type="bar" height={350} width={"100%"} />
    )
}