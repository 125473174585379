import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {ApiLoginHelper} from "../Helper/ApiHelper";
import {useContext} from "react";
import {AuthContext} from "../../context/AuthContextProvider";
import {useSnackbar} from "notistack";
import {decodeToken, useJwt} from "react-jwt";

export default function Login() {
    const authCtx = useContext(AuthContext);
    const [dataToSend, setDataToSend] = React.useState({email: '', password: ''});
    const { enqueueSnackbar } = useSnackbar();
    const { reEvaluateToken } = useJwt("");
    const handleSubmit = (event) => {
        event.preventDefault();
        ApiLoginHelper(dataToSend)
            .then( (response) => {
                const myDecodedToken = decodeToken(response.data.token);
                reEvaluateToken(response.data.token)
                console.log(response.data.token)
                authCtx.login({id: response.data.id, username: myDecodedToken.username, isLoggedIn: true, token: response.data.token})
                enqueueSnackbar('Vous êtes désormais connecté', {variant: 'success'})
            })
            .catch( error => {
                console.log(error)
                { enqueueSnackbar('Une erreur est survenue', {variant: 'error'}) }
            })
    };

    return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Me connecter
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(event) => setDataToSend({...dataToSend, email: event.target.value})}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(event) => setDataToSend({...dataToSend, password: event.target.value})}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Connexion
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
    );
}